body {
  display: flex;
  text-align: center;
  font-family: "Work Sans", sans-serif;
  background: rgb(252, 125, 70);
  background: radial-gradient(
    circle,
    rgba(252, 125, 70, 1) 0%,
    rgba(251, 230, 221, 1) 86%
  );
  background-size: cover;
  margin: 0;
  justify-content: center;
}

code {
  font-family: "Work Sans", sans-serif;
}

ul.link-list li {
  display: inline;
  list-style-type: none;
  margin: 0;
  text-decoration: none;
}

ul.link-list {
  padding: 10px;
  margin: 0;
  align-items: center;
}

ul.link-list li a {
  padding-right: 10px;
  display: inline-block;
}
